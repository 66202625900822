import React, { useEffect, useState, Suspense } from 'react'
import { Outlet } from 'react-router-dom'
import useAuth from '../../hooks/useAuth'
import useRefreshToken from '../../hooks/useRefreshToken'
import LoaderSpinner from '../LoaderSpinner/LoaderSpinner'
import { useGetCurrentUser } from '../../hooks/useTokenAPI'

import SuspenseCard from '../SuspenseCard/SuspenseCard'

const PersistLogin = () => {

  const refresh = useRefreshToken();
  const currentUser = useGetCurrentUser();
  const { accessToken, csrftoken } = useAuth();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let isMounted = true

    const verifyUser = () => {
      refresh();

      isMounted && setLoading(false);
    };

    !accessToken ? verifyUser() : setLoading(false)

    return () => {isMounted = false};
  }, []);

  useEffect(() => {
    if(accessToken !== '') {currentUser()};
  }, [accessToken, csrftoken]);

  return (
    loading ? <LoaderSpinner /> : <Suspense fallback={<SuspenseCard />}><Outlet /></Suspense>
  );
};

export default PersistLogin;
