import { createContext, useState } from "react";

const AuthContext = createContext({
  status: null,
  user: {},
  setUser: () => { },
  accessToken: null,
  refreshToken: null,
  csrftoken: null,
  setAccessToken: () => { },
  setRefreshToken: () => { },
  setCSRFToken: () => { }
});

export const AuthContextProvider = ({ children }) => {

  const [authError, setAuthError] = useState();
  const [status, setStatus] = useState();
  const [user, setUser] = useState({});
  const [userStats, setUserStats] = useState({
    treatAndExam: [],
    centers: []
  });
  
  const [dentistsPatients, setDentistsPatients] = useState([])
  const [sortedDentalPatients, setSortedDentalPatients] = useState([]);

  const [accessToken, setAccessToken] = useState('')
  const [refreshToken, setRefreshToken] = useState('')
  const [csrftoken, setCSRFToken] = useState('')

  const [currentPage, setCurrentPage] = useState('');
  
  return (
    <AuthContext.Provider value={{
      status,
      authError, setAuthError,
      user, setUser,
      userStats, setUserStats,
      dentistsPatients, setDentistsPatients,
      sortedDentalPatients, setSortedDentalPatients,
      accessToken, setAccessToken,
      refreshToken, setRefreshToken,
      csrftoken, setCSRFToken,
      currentPage, setCurrentPage
    }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
