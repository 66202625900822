import useAxiosPrivate from "./useAxiosPrivate";
import useAuth from "./useAuth";

export const useGetCurrentUser = () => {
    const axiosPrivateInstance = useAxiosPrivate();
    const { setUser } = useAuth();
  
    const getCurrentUser = () => {
      axiosPrivateInstance.get('auth/user/')
      .then((getUserResp) => {
        setUser(getUserResp.data);
      })
      .catch((error) => {
        if (error.response !== undefined) {
          return error.response
        }
      })
    };
  
    return getCurrentUser;
  };