import axios from "axios";

var API_URL = '';

if(process.env.NODE_ENV === "production") {
  var API_URL = process.env.REACT_APP_GCP_API;
} else {
  var API_URL = process.env.REACT_APP_LOCAL_API;
};


export const axiosInstance = axios.create({
  baseURL: API_URL,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json"
  }
});

export const axiosPrivateInstance = axios.create({
  baseURL: API_URL,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json"
  }
});
