import './positionlessSidebar.css';
import { NavLink } from 'react-router-dom';
import { AiOutlineHome, AiOutlineLogout } from 'react-icons/ai';


const Positionless = ({ onLogout, handleSidebarSelection }) => {

  const content = (
    <div className='positionless-sidebar-column'>
      <nav className='positionless-sidebar app-sidebar'>
        <NavLink
          className={'navlink dashboard'}
          name='dashboard'
          to={'/'}
          onClick={handleSidebarSelection}
        >
          <AiOutlineHome size={20}/>
        </NavLink>
        
      </nav>
      <nav className='positionless-logout-button-container'>
        <NavLink
          className='navlink logout'
          to={'/'}
          onClick={onLogout}
        >
          <AiOutlineLogout size={20}/>
        </NavLink>
      </nav>
    </div>
  );

  return content;
}

export default Positionless