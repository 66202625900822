import { createContext, useState } from "react";
import { toast } from 'react-toastify';

const RegisterContext = createContext({});

export const RegisterContextProvider = ({ children }) => {

  const [registerMedCenter, setRegisterMedCenter] = useState({
    med_center_name:'',
    med_center_address: ''
  });
  
  // ===================== Registration Changes =====================:
  const handleRegMedCenter = e => {
    e.preventDefault();
    setRegisterMedCenter(prevData => ({
      ...prevData,
      [e.target.name]: e.target.value
    }))
  };

  const resetRegMedCenter = () => {
    setRegisterMedCenter({
      med_center_name: '',
      med_center_address: ''
    })
  };

   // ===================== Toastify Popups =======================
   const successPopup = (message) => toast.success(
    message,
    {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    }
  );

  const errorPopup = (message) => toast.error(
    message,
    {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    }
  );

  return (
    <RegisterContext.Provider value={{
      registerMedCenter,
      handleRegMedCenter,
      resetRegMedCenter,
      successPopup,
      errorPopup
    }}>
      {children}
    </RegisterContext.Provider>
  );
};

export default RegisterContext;
