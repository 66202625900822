import './sidebarMain.css';
import { useNavigate } from "react-router-dom";
import DentistSidebar from '../Dentist/DentistSidebar';
import PositionlessSidebar from '../Positionless/PositionlessSidebar';
import SecretarySidebar from '../Secretary/SecretarySidebar';
import HygienistSidebar from '../Hygienist/HygienistSidebar';
import useAuth from '../../../hooks/useAuth';
import useLogout from '../../../hooks/useLogout';
import useAppContext from '../../../hooks/useAppContext';


const SidebarMain = ({ children }) => {

  const { accessToken, user } = useAuth();
  const { setSelectedNavLink } = useAppContext();

  const logout = useLogout();
  const navigate = useNavigate();

  const onLogout = async(e) => {
    e.preventDefault();
    await logout()
    navigate('/')
  };

  const handleSidebarSelection = e => {
    setSelectedNavLink(e.currentTarget.name)
  };

  const sidebarLookup = {
    1: <PositionlessSidebar
      onLogout={onLogout}
      handleSidebarSelection={handleSidebarSelection}
    />,
    2: <DentistSidebar
      name={user.first_name !== undefined ? Array.from(user.first_name)[0].toUpperCase() : ''}
      onLogout={onLogout}
      handleSidebarSelection={handleSidebarSelection}
    />,
    3: <SecretarySidebar
      name={user.first_name !== undefined ? Array.from(user.first_name)[0].toUpperCase() : ''}
      onLogout={onLogout}
      handleSidebarSelection={handleSidebarSelection}
    />,
    6: <HygienistSidebar 
      onLogout={onLogout}
      handleSidebarSelection={handleSidebarSelection}
    />
  }

  const content = (
    <div className='sidebar-container'>
      <div className={`sidebar-dynamic-container ${!accessToken ? 'hidden-sidebar' : ''}`}>
        {
          accessToken ? 
            <div className='sidebar-sidebar-nav'>
              {sidebarLookup[user.position_id]}
            </div> :
            ''
        }
      </div>
      <div className={`sidebar-children-container ${!accessToken ? 'show-login' : 'hide-login'}`}>
        {children}
      </div>
    </div>
  );

  return content;
}

export default SidebarMain