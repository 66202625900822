import { axiosInstance } from "../axios";
import useAuth from "./useAuth";
import { useCheckIfAuthorized } from "./useCheckIfAuth";

const useRefreshToken = () => {
  const { setAccessToken, setCSRFToken, setAuthError } = useAuth();
  const checkAuth = useCheckIfAuthorized();

  const refresh = () => {
    axiosInstance.post('auth/refresh/')
    .then((response) => {
      setAccessToken(response.data.access);
      setCSRFToken(response.headers["x-csrftoken"]);
      setAuthError('');
    })
    .catch((error) => {
      setAuthError(error.response);
      setAccessToken('');
      checkAuth();
    })
  };

  return refresh;
};

export default useRefreshToken;
