import { createContext, useState } from "react";
import { toast } from 'react-toastify';


const PatientSearchContext = createContext({});

export const PatientSearchContextProvider = ({ children }) => {

  const title = {
    1: 'Search',
    2: 'Patient File'
  };

  const [isLoading, setIsLoading] = useState(false);
  const [isFiltered, setIsFiltered] = useState(true);
  const [page, setPage] = useState(1);
  const [vistitedPage, setVisitedPage] = useState({
    1: true,
    2: false
  });

  // Search criteria:
  const [dentistFilter, setDentistFilter] = useState([]);

  const [medcenterFilter, setMedcenterFilter] = useState([]);

  const [selectedPatient, setSelectedPatient] = useState('');

  // File to view:
  const [template, setTemplate] = useState(
    {
      "basePdf": "",
      "sampledata": [{}],
      "columns": [],
      "schemas": []
    }
  );

  // File to download:
  const [selectedFile, setSelectedFile] = useState([]);
  const [forView, setForView] = useState('');
  const [forDownload, setForDownload] = useState([]);
  
  // ===================== Handlers =======================
  const handleSelectingSearchableDentist = (e) => {
    setDentistFilter(e);
  };

  const handleSelectingFilterMedCenter = e => {
    setMedcenterFilter(e);
  };

  // ===================== Toastify Popups =======================
  const successPopup = (message) => toast.success(
    message,
    {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    }
  );

  const errorPopup = (message) => toast.error(
    message,
    {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    }
  );

  return (
    <PatientSearchContext.Provider value={{
      title,
      dentistFilter, setDentistFilter,
      forView, setForView,
      forDownload, setForDownload,
      isFiltered, setIsFiltered,
      isLoading, setIsLoading,
      medcenterFilter, setMedcenterFilter,
      page, setPage,
      selectedFile, setSelectedFile,
      selectedPatient, setSelectedPatient,
      template, setTemplate,
      vistitedPage, setVisitedPage,
      handleSelectingSearchableDentist,
      handleSelectingFilterMedCenter,
      errorPopup,
      successPopup,
    }}>
      {children}
    </PatientSearchContext.Provider>
  );
};

export default PatientSearchContext
