import { Outlet } from 'react-router-dom';
import { RegisterContextProvider } from '../context/RegisterContext';

const RegisterLayountContext = () => {

  return (
    <RegisterContextProvider>
      <Outlet />
    </RegisterContextProvider>
  );
};

export default RegisterLayountContext;