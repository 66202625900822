import './secretarySidebar.css';
import { NavLink } from 'react-router-dom';
import { AiOutlineHome, AiOutlineLogout } from 'react-icons/ai';
import { MdContentPasteSearch } from 'react-icons/md';
import { FaRegCalendarPlus } from "react-icons/fa";

const SecretarySidebar = ({ name, onLogout, handleSidebarSelection }) => {
  const content = (
    <div className='secretary-sidebar-column'>
      <div className='secretary-name'>{name}</div>
      <div className='sidebar-hr-container'>
        <hr className='sidebar-hr'/>
      </div>
      <nav className='secretary-sidebar app-sidebar'>
        <NavLink
          className={'navlink dashboard'}
          name='dashboard'
          to={'/'}
          onClick={handleSidebarSelection}
        >
          <AiOutlineHome size={20}/>
        </NavLink>
        <NavLink
          className={'navlink patient-search'}
          name='patient-search'
          to={'/form_search'}
          onClick={handleSidebarSelection}
        >
          <MdContentPasteSearch size={20}/>
        </NavLink>
        <NavLink
          className={'navlink patient-search'}
          name='patient-search'
          to={'/appointment_booking'}
          onClick={handleSidebarSelection}
        >
          <FaRegCalendarPlus size={20}/>
        </NavLink>
      </nav>
      <div className='sidebar-hr-container'>
        <hr className='sidebar-hr'/>
      </div>
      <nav className='secretary-logout-button-container'>
        <NavLink
          className='navlink logout'
          to={'/'}
          onClick={onLogout}
        >
          <AiOutlineLogout size={20}/>
        </NavLink>
      </nav>
    </div>
  );

  return content;
}

export default SecretarySidebar