import { createContext, useState } from "react";

const AppContext = createContext({});

export const AppContextProvider = ({ children }) => {

  const [dentistInfo, setDentistInfo] = useState('');
  const [selectedNavLink, setSelectedNavLink] = useState('none');

  return (
    <AppContext.Provider value={{
      dentistInfo, setDentistInfo,
      selectedNavLink, setSelectedNavLink
    }}>
      {children}
    </AppContext.Provider>
  );
};

export default AppContext;
