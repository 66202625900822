import './hygienistSidebar.css';
import { NavLink } from 'react-router-dom';
import { AiOutlineHome, AiOutlineLogout } from 'react-icons/ai';
import { MdContentPasteSearch } from 'react-icons/md';

const HygienistSidebar = ({ onLogout, handleSidebarSelection }) => {
  const content = (
    <div className='hygienist-sidebar-column'>
      <nav className='hygienist-sidebar app-sidebar'>
        <NavLink
          className={'navlink dashboard'}
          name='dashboard'
          to={'/'}
          onClick={handleSidebarSelection}
        >
          <AiOutlineHome size={20}/>
        </NavLink>
        <NavLink
          className={'navlink patient-search'}
          name='patient-search'
          to={'/hygienist/form_search'}
          onClick={handleSidebarSelection}
        >
          <MdContentPasteSearch size={20}/>
        </NavLink>
      </nav>
      <nav className='hygienist-logout-button-container'>
        <NavLink
          className='navlink logout'
          to={'/'}
          onClick={onLogout}
        >
          <AiOutlineLogout size={20}/>
        </NavLink>
      </nav>
    </div>
  );

  return content;
}

export default HygienistSidebar