import { createContext, useState } from "react";
import { toast } from 'react-toastify';

const HygienistContext = createContext({});

export const HygienistContextProvider = ({ children }) => {

  const [isLoading, setIsLoading] = useState(false);

  // Search criteria:
  const [searchParams, setSearchParams] = useState({
    start_date: new Date().toJSON().slice(0, 10),
    end_date: new Date().toJSON().slice(0, 10),
    firstName: '',
    lastName: '',
    fileNumber: ''
  });
  const [searchResults, setSearchResults] = useState([]);

  // Filter criteria:
  const [officeDentists, setOfficeDentists] = useState([]);
  const [dentistFilter, setDentistFilter] = useState([]);

  const [medicalCenters, setMedicalCenters] = useState([]);
  const [medcenterFilter, setMedcenterFilter] = useState([]);

  // File to view:
  const [template, setTemplate] = useState(
    {
      "basePdf": "",
      "sampledata": [{}],
      "columns": [],
      "schemas": []
    }
  );

  // File to download:
  const [selectedFile, setSelectedFile] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [forView, setForView] = useState('');
  const [forDownload, setForDownload] = useState([]);
  
  // ===================== Handlers =======================
  const handleSelectingSearchableDentist = (e) => {
    setDentistFilter(e);
  };

  const handleSelectingFilterMedCenter = e => {
    setMedcenterFilter(e);
  };

  // ===================== Toastify Popups =======================
  const successPopup = (message) => toast.success(
    message,
    {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    }
  );

  const errorPopup = (message) => toast.error(
    message,
    {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    }
  );

  return (
    <HygienistContext.Provider value={{
      isLoading, setIsLoading,
      officeDentists, setOfficeDentists,
      dentistFilter, setDentistFilter,
      medicalCenters, setMedicalCenters,
      medcenterFilter, setMedcenterFilter,
      searchResults, setSearchResults,
      template, setTemplate,
      selectedFile, setSelectedFile,
      selectAll, setSelectAll,
      forView, setForView,
      forDownload, setForDownload,
      handleSelectingSearchableDentist,
      handleSelectingFilterMedCenter,
      searchParams, setSearchParams,
      successPopup, errorPopup,
    }}>
      {children}
    </HygienistContext.Provider>
  );
};

export default HygienistContext
