import useAuth from "./useAuth";
import { useNavigate } from 'react-router-dom';


export const useCheckIfAuthorized = () => {
  const { setAccessToken, authError } = useAuth()
  const navigate = useNavigate();

  const checkAuth = () => {
    if (authError?.status === 401) {
      setAccessToken('');
      navigate('/');
    }
  }

  return checkAuth;
};
