import useAuth from "./useAuth"
// import { axiosPrivateInstance } from "../axios"
import useAxiosPrivate from "./useAxiosPrivate";

const useLogout = () => {
  const { setUser, setAccessToken, setCSRFToken } = useAuth()
  const axiosPrivateInstance = useAxiosPrivate();

  const logout = async () => {
    try {
      const response = await axiosPrivateInstance.post(
        "auth/logout/",
      )

      setAccessToken(null)
      setCSRFToken(null)
      setUser({})

    } catch (error) {
      // console.log(error)
    }
  }

  return logout;
};

export default useLogout;
