import { axiosPrivateInstance } from "../axios";
import { useEffect } from 'react'
import useAuth from "./useAuth";
import useRefreshToken from "./useRefreshToken";
import { useNavigate, useLocation } from 'react-router-dom';

const useAxiosPrivate = () => {

  const { accessToken, setAccessToken, csrftoken, user } = useAuth()
  const navigate = useNavigate();
  const refresh = useRefreshToken();

  useEffect(() => {
    const requestIntercept = axiosPrivateInstance.interceptors.request.use(
      (config) => {
        if (!config.headers["Authorization"]) {
          config.headers['Authorization'] = `Bearer ${accessToken}`;
          config.headers['x-csrftoken'] = csrftoken
          config.headers['user'] = `${user.first_name} ${user.last_name}`
        }
        return config
      },
      (error) => Promise.reject(error)
    )

    const responseIntercept = axiosPrivateInstance.interceptors.response.use(
      response => response,
      async (error) => {
        const prevRequest = error?.config;
          if ((error?.response?.status === 401) && !prevRequest?.sent) {
            prevRequest.sent = true;
            try {
              const {
                csrfToken: newCSRFToken,
                accessToken: newAccessToken,
              } = await refresh();

              setAccessToken(newAccessToken)
              prevRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
              prevRequest.headers['x-csrftoken'] = newCSRFToken
              prevRequest.headers['user'] = 'DEFAULT_USER'

              return axiosPrivateInstance(prevRequest)
            } catch {
                navigate('/');

              return Promise.reject(error);
            }
          }
        return Promise.reject(error);
      }
    );

    return () => {
      axiosPrivateInstance.interceptors.request.eject(requestIntercept)
      axiosPrivateInstance.interceptors.response.eject(responseIntercept)
    }
  }, [accessToken, user])

  return axiosPrivateInstance;
};

export default useAxiosPrivate;
