import './dentistSidebar.css';
import { NavLink } from 'react-router-dom';
import { PiListMagnifyingGlassBold, PiTooth } from 'react-icons/pi';
import { AiOutlineFileAdd, AiOutlineHome, AiOutlineLogout } from 'react-icons/ai';
import { MdContentPasteSearch } from 'react-icons/md';
import { FaTruckMoving } from 'react-icons/fa';


const DentistSidebar = ({ name, onLogout, handleSidebarSelection }) => {

  const content = (
    <div className='dentist-sidebar-column'>
      <div className='dentist-name'>{name}</div>
      <div className='sidebar-hr-container'>
        <hr className='sidebar-hr'/>
      </div>
      <nav className='dentist-sidebar app-sidebar'>
        <NavLink
          className={'navlink dashboard'}
          name='dashboard'
          to={'/'}
          onClick={handleSidebarSelection}
        >
          <AiOutlineHome size={20}/>
        </NavLink>
        <NavLink
          className={'navlink register'}
          name='register'
          to={'/register'}
          onClick={handleSidebarSelection}
        >
          <div>
            <AiOutlineFileAdd size={20}/>
          </div>
        </NavLink>
        <NavLink
          className={'navlink examination'}
          name='deplacement'
          to={'/deplacement'}
          onClick={handleSidebarSelection}
        >
          <FaTruckMoving size={20}/>
        </NavLink>
        <NavLink
          className={'navlink examination'}
          name='examination'
          to={'/examination'}
          onClick={handleSidebarSelection}
        >
          <PiListMagnifyingGlassBold size={20}/>
        </NavLink>
        <NavLink
          className={'navlink treatment'}
          name='treatment'
          to={'/treatment'}
          onClick={handleSidebarSelection}
        >
          <PiTooth size={20}/>
        </NavLink>
        <NavLink
          className={'navlink patient-search'}
          name='patient-search'
          to={'/patient_search'}
          onClick={handleSidebarSelection}
        >
          <MdContentPasteSearch size={20}/>
        </NavLink>
      </nav>
      <div className='sidebar-hr-container'>
        <hr className='sidebar-hr'/>
      </div>
      <nav className='dentist-logout-button-container'>
        <NavLink
          className='navlink logout'
          to={'/'}
          onClick={onLogout}
        >
          <AiOutlineLogout size={20}/>
        </NavLink>
      </nav>
    </div>
  );

  return content;
}

export default DentistSidebar