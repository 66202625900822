import React from 'react';
import './suspenseCard.css';
import LoaderSpinner from '../LoaderSpinner/LoaderSpinner';

const SuspenseCard = () => {

  const content = (
    <div className='suspense-card-container'>
      <LoaderSpinner />
    </div>
  )

  return content;
}

export default SuspenseCard