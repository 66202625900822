import useAuth from "../hooks/useAuth";
import { Navigate, Outlet, useLocation } from "react-router-dom";

const AuthMiddleware = () => {
  const { accessToken } = useAuth();
  const location = useLocation();

  // console.log('location', location)

  return (
    accessToken
    ? <Outlet />
    : <Navigate to="/" state={{ from: location }} replace />
  );
};

export default AuthMiddleware;
