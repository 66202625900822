import React, { useState, useEffect } from 'react';
import './loaderSpinner.css';
import { Dna } from 'react-loader-spinner';

const LoaderSpinner = () => {

  const [msg, setMsg] = useState('');

  var msgTimer;

  useEffect(() => {
    msgTimer = setTimeout(function() {
      setMsg('Something may have gone wrong. Please contact administrator. This message will disappear shortly.')
    }, 45 * 1000);

    return () => {
      clearTimeout(msgTimer)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const content = (
    <div className='loader_spinner__overlay'>
      <div className='loader_spinner__container'>
        <Dna
          visible={true}
          height="100"
          width="100"
          ariaLabel="dna-loading"
          wrapperStyle={{}}
          wrapperClass="dna-wrapper"
        />
        <div className='loader-spinner-msg'>{msg}</div>
      </div>
    </div>
  );

  return content;
}

export default LoaderSpinner